import React, { useState} from 'react';
import './coronamaison.css';
import useWindowSize from './dimensionsHelper';
import Fuse from 'fuse.js';
import {Link} from 'react-router-dom';

const CoronaSearch = ({locale, data, setQueryResult, setCurrentQueryResult}) => {
  const getDimensions = (pw, ph) => {
    let w = pw;
    let h = w / ratio;
    const reduceFactor = 0.8;
    if( h > ph * reduceFactor) {
      h = ph * reduceFactor;
      w = h * ratio;
    }
    return {w, h};
  }

  const ratio = 1.86;
  const size = useWindowSize();
  const originalDimensions = getDimensions(size.width, size.height);
  const [dimensions, setDimensions] = useState({ 
    height: originalDimensions.h,
    width: originalDimensions.w
  })

  React.useEffect(() => {
    const handleResize = () => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      const newDimensions = getDimensions(w, h);
      setDimensions({
        height: newDimensions.h,
        width: newDimensions.w
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions]);

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'fit'
  }

  const containerStyle = {
    width:`${dimensions.width}px`,
    height:`${dimensions.height}px`,
    position:'relative',
    margin: 'auto'
  }

  const frontStyle = {
    position: 'absolute',
    height: `${dimensions.height}px`,
    width: '100%',
    zIndex:1,
    top:0,
    left:0
  }

  const toolsStyle = {
    position: 'absolute',
    height: `${dimensions.height}px`,
    zIndex:4,
    top:0,
    right:'20px',
    fontSize:'2vw'
  }
  
  return (
    <div style={containerStyle}>
      <div className={'content'}>
        <div style={frontStyle}>
          <img src="../searchtemplate.jpg" alt={'template'} style={imgStyle}/>
        </div>
        <div id='tools' style={toolsStyle}>
          <Link style={{ textDecoration: 'none' }} to="/"><span role="img" aria-label="img">🏠</span> </Link>
        </div>
        <InfosComponent locale={locale} data={data} setQueryResult={setQueryResult} setCurrentQueryResult={setCurrentQueryResult} />
      </div>
    </div>
  );
};

export default CoronaSearch;

const InfosComponent = ({locale, data, setQueryResult, setCurrentQueryResult}) => {
  const [query, setQuery] = useState('');
  const infosStyle = {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    flexBasis:0,
    zIndex:3,
    top: '20%',
    width: '75%',
    transform: 'translateY(-50%) translateX(-50%)',
    left: '55%'
  }
  const subStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexBasis:0,
    marginTop: '3vw',
    marginLeft: '5%',
    alignItems: 'center'
  }
  const onChangeText = (e) => {
    setQuery(e.target.value);
  }

  const search = (e) => {
    const options = {
      includeScore: true,
      keys: ['account', 'name']
    }

    const fuse = new Fuse(data, options)

    const result = fuse.search(query);
    const resultWithThreshold = result.filter((res) => {
      return res.score < 0.2
    })

    const resultWithFullData = [];
    for( let obj of resultWithThreshold) {
      resultWithFullData.push(obj.item);
    }

    setQueryResult(resultWithFullData);
    setCurrentQueryResult(resultWithFullData.slice(0,9));
  }
  if(locale === 'fr') {
    return (
      <div style={infosStyle}>
            <div id="subtitle2">Rechercher un étage de la Coronamaison :</div>
            <div style={subStyle}>
              <div className="author">Auteur·e :</div>
              <input onChange={onChangeText} type="text" className="txt input" value={query}></input>
              <div className="searchBtn" onClick={search}>Rechercher</div>
            </div>
        </div>
    )
  } else {
    return (
      <div style={infosStyle}>
        <div id="subtitle2">Look up for a floor in the Coronamaison:</div>
        <div style={subStyle}>
            <div className="author">Author:</div>
            <input onChange={onChangeText} type="text" className="txt input" value={query}></input>
            <div className="searchBtn" onClick={search}>Search</div>
          </div>
      </div>
    )
  }
}