import React, { useState} from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import './App.css';
import data from './coronamaison.json';
import Coronamaison from './coronamaison';
import _ from 'underscore';
import CoronaTemplate from './coronatemplate';
import CoronaSearch from './coronasearch';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  const dataShuffled = _.shuffle(data);
  const [coronamaisons, setCoronamaisons] = useState(dataShuffled.slice(0,9));
  const [index, setIndex] = useState(0);
  const [indexQuery, setIndexQuery] = useState(0);
  const [queryResults, setQueryResult] = useState([]);
  const [currentQueryResults, setCurrentQueryResult] = useState([]);
  const [locale, setParentLocale] = useState('fr');
  const fetchMoreData = () => {
    const newIndex = index +1;
    setIndex(newIndex);
    setCoronamaisons(coronamaisons.concat(dataShuffled.slice(newIndex * 10, newIndex * 10 + 9)))
  };

  const fetchMoreQueryResults = () => {
    const newIndex = indexQuery +1;
    setIndexQuery(newIndex);
    setCurrentQueryResult(currentQueryResults.concat(queryResults.slice(newIndex * 10, newIndex * 10 + 9)))
  };

  return (
    <Router>
      <Switch>
           <Route exact path="/">
           <div id='container'>
            <div id="scrollableDiv" style={{ height: ' 900px', overflow: "auto" }}>
              <InfiniteScroll
                dataLength={coronamaisons.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <CoronaTemplate height={data.length} setParentLocale={setParentLocale}></CoronaTemplate>
                {coronamaisons.map((i, key) => (
                  <Coronamaison key={key} image={i.img} account={i.account} tweet={i.tweet} name={i.name}>
                    div - #{key}
                  </Coronamaison>
                ))}
              </InfiniteScroll>
            </div>
          </div>
            </Route>
          <Route path="/search/">
          <div id='container'>
              <div id="scrollableDiv" style={{ height: ' 800px', overflow: "auto" }}>
              <InfiniteScroll
                dataLength={queryResults.length}
                next={fetchMoreQueryResults}
                hasMore={true}
                loader={<h4>Searching...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <CoronaSearch 
                locale={locale}
                data={data}
                setQueryResult={setQueryResult}
                setCurrentQueryResult={setCurrentQueryResult}/>
                {currentQueryResults.map((i, key) => (
                  <Coronamaison key={key} image={'../'+i.img} account={i.account} tweet={i.tweet} name={i.name}>
                    div - #{key}
                  </Coronamaison>
                ))}
              </InfiniteScroll>
            </div>
          </div>
          </Route>
        </Switch>
    </Router>
  );
}

export default App;

