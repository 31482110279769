import React, { useState} from 'react';
import './coronamaison.css';
import useWindowSize from './dimensionsHelper';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
  faTwitter
);

const Coronamaison = ({image, account, tweet, name}) => {
  const getDimensions = (pw, ph) => {
    let w = pw;
    let h = w / ratio;
    const reduceFactor = 0.8;
    if( h > ph * reduceFactor) {
      h = ph * reduceFactor;
      w = h * ratio;
    }
    return {w, h};
  }

  const ratio = 1.875;
  const size = useWindowSize();
  const originalDimensions = getDimensions(size.width, size.height);
  const [dimensions, setDimensions] = useState({ 
    height: originalDimensions.h,
    width: originalDimensions.w
  })
  React.useEffect(() => {
    const doc = document.querySelector('.infinite-scroll-component');
    doc.style.overflow = 'hidden';
    const handleResize = () => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      const newDimensions = getDimensions(w, h);
      setDimensions({
        height: newDimensions.h,
        width: newDimensions.w
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions]);

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'fit'
  }

  const containerStyle = {
    width:`${dimensions.width}px`,
    height:`${dimensions.height}px`,
    position:'relative',
    margin: 'auto'
  }

  const frontStyle = {
    position: 'absolute',
    width: '100%',
    zIndex:1,
    top:0,
    left:0
  }

  const infosStyle = {
    position: 'absolute',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexBasis:0,
    zIndex:2,
    bottom: '0px',
    right:'0px',
    opacity: 0.7
  }
  
  if(name) {
    return (
      <div style={containerStyle}>
        <div className={'content'}>
          <div style={frontStyle}>
            <img src={image} alt={image} style={imgStyle}/>
          </div>
          <div style={infosStyle}>
            <div className="account">{name}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={containerStyle}>
        <div className={'content'}>
          <div style={frontStyle}>
            <img src={image} alt={image} style={imgStyle}/>
          </div>
          <div style={infosStyle}>
            <div className="account">
              <FontAwesomeIcon icon={faTwitter} />
              <a href={`https://twitter.com/${account}/status/${tweet}`} target="blank">{account}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Coronamaison;