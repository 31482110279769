import React, { useState} from 'react';
import './coronamaison.css';
import useWindowSize from './dimensionsHelper';
import {Link} from 'react-router-dom';

const CoronaTemplate = ({height, setParentLocale}) => {
  const getDimensions = (pw, ph) => {
    let w = pw;
    let h = w / ratio;
    const reduceFactor = 0.8;
    if( h > ph * reduceFactor) {
      h = ph * reduceFactor;
      w = h * ratio;
    }
    return {w, h};
  }

  const ratio = 1.875;
  const size = useWindowSize();
  const originalDimensions = getDimensions(size.width, size.height);
  const [dimensions, setDimensions] = useState({ 
    height: originalDimensions.h,
    width: originalDimensions.w
  })
  const [locale, setLocale] = useState('fr');
  React.useEffect(() => {
    const handleResize = () => {
      const w = window.innerWidth;
      const h = window.innerHeight;
      const newDimensions = getDimensions(w, h);
      setDimensions({
        height: newDimensions.h,
        width: newDimensions.w
      });
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dimensions]);

  const imgStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'fit'
  }

  const containerStyle = {
    width:`${dimensions.width}px`,
    height:`${dimensions.height}px`,
    position:'relative',
    margin: 'auto'
  }

  const frontStyle = {
    position: 'absolute',
    height: `${dimensions.height}px`,
    width: '100%',
    zIndex:1,
    top:0,
    left:0
  }

  const toolsStyle = {
    position: 'absolute',
    height: `${dimensions.height}px`,
    zIndex:4,
    top:0,
    right:'20px',
    fontSize:'2vw'
  }

  const changeLocale = (locale) => {
    setParentLocale(locale);
    setLocale(locale);
  }

  return (
    <div style={containerStyle}>
      <div className={'content'}>
        <div style={frontStyle}>
          <img src="template.jpeg" alt={'template'} style={imgStyle}/>
        </div>
        <div id='tools' style={toolsStyle}>
          <span onClick={() => {changeLocale('fr')}} role="img" aria-label="img">🇫🇷</span>
          <span onClick={() => {changeLocale('en')}} role="img" aria-label="img">🇬🇧</span>
          <Link style={{ textDecoration: 'none' }} to="/search"><span role="img" aria-label="img">🔍</span> </Link>
        </div>
        <InfosComponent locale={locale} height={height} />
      </div>
    </div>
  );
};

export default CoronaTemplate;

const InfosComponent = ({locale, height}) => {
  const infosStyle = {
    position: 'absolute',
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexBasis:0,
    zIndex:3,
    opacity: 0.7,
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    left: '55%'
  }
  if(locale === 'fr') {
    return (
      <div style={infosStyle}>
            <div id="titleheader">#CoronaMaison</div>
            <div id="subtitle">Pendant l’isolement de la pandémie de Coronavirus COVID 19 :</div>
            <br />
            <div className="mini">- <a className="green" href="https://twitter.com/PenelopeB" target="blank">Pénélope Bagieu</a> : On pourrait faire un grand cadavre exquis ensemble !</div>
            <div className="mini">- <a className="green" href="https://twitter.com/OssKx" target="blank">Oscar Barda</a> : On devrait faire un immeuble géant où chacun dessine sa pièce idéale pour passer le confinement !</div>
            <div className="mini">- <a className="green" href="https://twitter.com/garagedeloffre">Sandrine Deloffre</a> : Quelqu’un fait un template (gabarit) histoire qu’on dessine tous des pièces qui se ressemblent ?</div>
            <div className="mini">- <a className="green" href="https://twitter.com/acupoftim" target="blank">Timothy Hannem</a> : Je l’ai fait !</div>
            <div className="mini">- <a className="green" href="https://twitter.com/MrGrob" target="blank">Benoît Luce</a> et <a className="green" href="https://twitter.com/RetroDev">Antonin Segault</a> : Et nous on va en faire un site pour qu’on soit tous ensemble !</div>
            <br />
            <div className="mini"><a className="green" href="template.jpeg" target="blank">Télécharger</a> le gabarit de la CoronaMaison, dessinez votre pièce préférée et postez-la sur les 
    réseaux sociaux<br></br>avec le hashtag #CoronaMaison pour rejoindre notre immeuble commun !</div>
            <br />
            <div className="mini">L'immeuble fait actuellement {height} étages, soit une hauteur de {height * 3 / 1000} kms!</div>
            <br />
            <div className="mini">Contactez directement les auteurs individuellement pour les droits de reproduction presse.<br />Pour toutes questions liées au site lui-même, <a className="green" href="mailto:contact@coronamaison.fun">envoyez-nous un mail</a></div>
        </div>
    )
  } else {
    return (
      <div style={infosStyle}>
            <div id="titleheader">#CoronaMaison</div>
            <div id="subtitle">In the early days of social distancing during the COVID-19 pandemic:</div>
            <br />
            <div className="mini">- <a className="green" href="https://twitter.com/PenelopeB" target="blank">Pénélope Bagieu</a>: Let's play a giant game of <a href="https://en.wikipedia.org/wiki/Exquisite_corpse">exquisite corpse</a> (collaborative drawing)!</div>
            <div className="mini">- <a className="green" href="https://twitter.com/OssKx" target="blank">Oscar Barda</a>: Yes! We could each draw our ideal isolation room!</div>
            <div className="mini">- <a className="green" href="https://twitter.com/garagedeloffre">Sandrine Deloffre</a>: Can someone create a template so all the rooms look like they're part of the same building?</div>
            <div className="mini">- <a className="green" href="https://twitter.com/acupoftim" target="blank">Timothy Hannem</a>: Done!</div>
            <div className="mini">- <a className="green" href="https://twitter.com/MrGrob" target="blank">Benoît Luce</a> et <a className="green" href="https://twitter.com/RetroDev">Antonin Segault</a>: And we'll make a website so we can all live together!</div>
            <br />
            <div className="mini">To join our CoronaBuilding, <a className="green" href="template.jpeg" target="blank">download the CoronaMaison template</a>,<br />draw your ideal room, and post it on social media using the hashtag #CoronaMaison.</div>
            <br />
            <div className="mini">The building is {height} floors high, i.e. {height * 3 / 1000} kms high!</div>
            <br />
            <div className="mini">Each floor belongs to its respective author, whom you should contact directly regarding<br />press interviews and reproduction rights.<br />
For any questions about the website itself, <a className="green" href="mailto:contact@coronamaison.fun">please shoot us an email!</a></div>
        </div>
    )
  }
}